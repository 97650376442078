<template>
  <div class="memory">
    <div class="container" v-if="showStatus != 3">
      <Practice v-if="isPractice"></Practice>
      <isRight
        :answer="correct_type"
        v-if="isPractice && showStatus == 4"
      ></isRight>
      <Focus v-if="showStatus == 1"></Focus>
      <div v-if="showStatus == 2" style="margin-top: 75px">
        <div class="faceBox">
          <img v-show="showFace" :src="memoryList[current].src" alt="" />
        </div>
        <ul class="btns">
          <li v-for="(item, index) in btnList" :key="index">
            <div :class="item.key == active ? 'keyBtn active' : 'keyBtn'">
              {{ item.key }}
            </div>
            <p :class="item.key == active ? 'name activeName' : 'name'">
              {{ item.name }}
            </p>
          </li>
        </ul>
      </div>
    </div>
    <BlackScreen v-if="showStatus == 3"></BlackScreen>
  </div>
</template>

<script>
import Practice from '@/components/practice.vue';
import Focus from '@/components/focus.vue';
import isRight from '@/components/isRight.vue';
import BlackScreen from '@/components/blackScreen.vue';
import { saveSubjectiveAns } from '@/api/index';
//数据
let practiceData = [
  {
    id: 'EX1_memory_old',
    correct_answer: 'Q',
    src: require('../../assets/image/face/memory_excercise/EX1_memory_old.jpg'),
  },
  {
    id: 'EX2_memory_new',
    correct_answer: 'P',
    src: require('../../assets/image/face/memory_excercise/EX2_memory_new.jpg'),
  },
  {
    id: 'EX3_memory_old',
    correct_answer: 'Q',
    src: require('../../assets/image/face/memory_excercise/EX3_memory_old.jpg'),
  },
];
// //出现过的
// let Qdata = [
//   {
//     id: 'A1F6',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/A1F6.jpg'),
//   },
//   {
//     id: 'A1M7',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/A1M7.jpg'),
//   },
//   {
//     id: 'A2F10',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/A2F10.jpg'),
//   },
//   {
//     id: 'A2M6',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/A2M6.jpg'),
//   },
//   {
//     id: 'A3F3',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/A3F3.jpg'),
//   },
//   {
//     id: 'A3M11',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/A3M11.jpg'),
//   },
//   {
//     id: 'D1F7',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/D1F7.jpg'),
//   },
//   {
//     id: 'D1M4',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/D1M4.jpg'),
//   },
//   {
//     id: 'D2F7',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/D2F7.jpg'),
//   },
//   {
//     id: 'D2M8',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/D2M8.jpg'),
//   },
//   {
//     id: 'D3F3',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/D3F3.jpg'),
//   },
//   {
//     id: 'D3M10',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/D3M10.jpg'),
//   },
//   {
//     id: 'F1F10',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/F1F10.jpg'),
//   },
//   {
//     id: 'F1M1',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/F1M1.jpg'),
//   },
//   {
//     id: 'F2F3',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/F2F3.jpg'),
//   },
//   {
//     id: 'F2M7',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/F2M7.jpg'),
//   },
//   {
//     id: 'F3F2',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/F3F2.jpg'),
//   },
//   {
//     id: 'F3M3',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/F3M3.jpg'),
//   },
//   {
//     id: 'H1F2',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/H1F2.jpg'),
//   },
//   {
//     id: 'H1M5',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/H1M5.jpg'),
//   },
//   {
//     id: 'H2F1',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/H2F1.jpg'),
//   },
//   {
//     id: 'H2M1',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/H2M1.jpg'),
//   },
//   {
//     id: 'H3F8',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/H3F8.jpg'),
//   },
//   {
//     id: 'H3M9',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/H3M9.jpg'),
//   },
//   {
//     id: 'N1F10',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/N1F10.jpg'),
//   },
//   {
//     id: 'N1M5',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/N1M5.jpg'),
//   },
//   {
//     id: 'N2F3',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/N2F3.jpg'),
//   },
//   {
//     id: 'N2M10',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/N2M10.jpg'),
//   },
//   {
//     id: 'N3F8',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/N3F8.jpg'),
//   },
//   {
//     id: 'N3M8',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/N3M8.jpg'),
//   },
//   {
//     id: 'S1F9',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/S1F9.jpg'),
//   },
//   {
//     id: 'S1M6',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/S1M6.jpg'),
//   },
//   {
//     id: 'S2F5',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/S2F5.jpg'),
//   },
//   {
//     id: 'S2M3',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/S2M3.jpg'),
//   },
//   {
//     id: 'S3F6',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/S3F6.jpg'),
//   },
//   {
//     id: 'S3M9',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/S3M9.jpg'),
//   },
//   {
//     id: 'SU1F10',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/SU1F10.jpg'),
//   },
//   {
//     id: 'SU1M10',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/SU1M10.jpg'),
//   },
//   {
//     id: 'SU2F8',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/SU2F8.jpg'),
//   },
//   {
//     id: 'SU2M5',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/SU2M5.jpg'),
//   },
//   {
//     id: 'SU3F6',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/SU3F6.jpg'),
//   },
//   {
//     id: 'SU3M5',
//     correct_answer: 'Q',
//     src: require('@/assets/image/face/recognition/SU3M5.jpg'),
//   },
// ];
// //未出现过的
// let Pdata = [
//   {
//     id: 'A1F1_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/A1F1_new.jpg'),
//   },
//   {
//     id: 'A1M1_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/A1M1_new.jpg'),
//   },
//   {
//     id: 'A2F8_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/A2F8_new.jpg'),
//   },
//   {
//     id: 'A2M7_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/A2M7_new.jpg'),
//   },
//   {
//     id: 'A3F6_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/A3F6_new.jpg'),
//   },
//   {
//     id: 'A3M7_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/A3M7_new.jpg'),
//   },
//   {
//     id: 'D1F1_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/D1F1_new.jpg'),
//   },
//   {
//     id: 'D1M9_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/D1M9_new.jpg'),
//   },
//   {
//     id: 'D2F4_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/D2F4_new.jpg'),
//   },
//   {
//     id: 'D2M3_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/D2M3_new.jpg'),
//   },
//   {
//     id: 'D3F2_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/D3F2_new.jpg'),
//   },
//   {
//     id: 'D3M9_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/D3M9_new.jpg'),
//   },
//   {
//     id: 'F1F6_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/F1F6_new.jpg'),
//   },
//   {
//     id: 'F1M6_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/F1M6_new.jpg'),
//   },
//   {
//     id: 'F2F9_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/F2F9_new.jpg'),
//   },
//   {
//     id: 'F2M5_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/F2M5_new.jpg'),
//   },
//   {
//     id: 'F3F7_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/F3F7_new.jpg'),
//   },
//   {
//     id: 'F3M8_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/F3M8_new.jpg'),
//   },
//   {
//     id: 'H1F8_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/H1F8_new.jpg'),
//   },
//   {
//     id: 'H1M9_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/H1M9_new.jpg'),
//   },
//   {
//     id: 'H2F5_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/H2F5_new.jpg'),
//   },
//   {
//     id: 'H2M7_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/H2M7_new.jpg'),
//   },
//   {
//     id: 'H3F2_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/H3F2_new.jpg'),
//   },
//   {
//     id: 'H3M6_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/H3M6_new.jpg'),
//   },
//   {
//     id: 'N1F2_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/N1F2_new.jpg'),
//   },
//   {
//     id: 'N1M8_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/N1M8_new.jpg'),
//   },
//   {
//     id: 'N2F1_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/N2F1_new.jpg'),
//   },
//   {
//     id: 'N2M4_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/N2M4_new.jpg'),
//   },
//   {
//     id: 'N3F2_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/N3F2_new.jpg'),
//   },
//   {
//     id: 'N3M3_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/N3M3_new.jpg'),
//   },
//   {
//     id: 'S1F3_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/S1F3_new.jpg'),
//   },
//   {
//     id: 'S1M9_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/S1M9_new.jpg'),
//   },
//   {
//     id: 'S2F4_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/S2F4_new.jpg'),
//   },
//   {
//     id: 'S2M1_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/S2M1_new.jpg'),
//   },
//   {
//     id: 'S3F5_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/S3F5_new.jpg'),
//   },
//   {
//     id: 'S3M12_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/S3M12_new.jpg'),
//   },
//   {
//     id: 'SU1F6_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/SU1F6_new.jpg'),
//   },
//   {
//     id: 'SU1M7_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/SU1M7_new.jpg'),
//   },
//   {
//     id: 'SU2F1_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/SU2F1_new.jpg'),
//   },
//   {
//     id: 'SU2M3_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/SU2M3_new.jpg'),
//   },
//   {
//     id: 'SU3F1_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/SU3F1_new.jpg'),
//   },
//   {
//     id: 'SU3M10_new',
//     correct_answer: 'P',
//     src: require('../../assets/image/face/memory/SU3M10_new.jpg'),
//   },
// ];

let idData = [
  [
    'A1F6',
    'A1M1_new',
    'A2F8_new',
    'A2M6',
    'A3F6_new',
    'A3M11',
    'D1F1_new',
    'D1M4',
    'D2F4_new',
    'D2M8',
    'D3F3',
    'D3M9_new',
    'F1F10',
    'F1M6_new',
    'F2F9_new',
    'F2M7',
    'F3F2',
    'F3M8_new',
    'H1F8_new',
    'H1M5',
    'H2F1',
    'H2M7_new',
    'H3F2_new',
    'H3M9',
    'N1F10',
    'N1M8_new',
    'N2F1_new',
    'N2M10',
    'N3F8',
    'N3M3_new',
    'S1F9',
    'S1M9_new',
    'S2F5',
    'S2M1_new',
    'S3F5_new',
    'S3M9',
    'SU1F6_new',
    'SU1M10',
    'SU2F8',
    'SU2M3_new',
    'SU3F1_new',
    'SU3M5',
  ],
  [
    'A1F1_new',
    'A1M7',
    'A2F10',
    'A2M7_new',
    'A3F3',
    'A3M7_new',
    'D1F7',
    'D1M9_new',
    'D2F7',
    'D2M3_new',
    'D3F2_new',
    'D3M10',
    'F1F6_new',
    'F1M1',
    'F2F3',
    'F2M5_new',
    'F3F7_new',
    'F3M3',
    'H1F2',
    'H1M9_new',
    'H2F5_new',
    'H2M1',
    'H3F8',
    'H3M6_new',
    'N1F2_new',
    'N1M5',
    'N2F3',
    'N2M4_new',
    'N3F2_new',
    'N3M8',
    'S1F3_new',
    'S1M6',
    'S2F4_new',
    'S2M3',
    'S3F6',
    'S3M12_new',
    'SU1F10',
    'SU1M7_new',
    'SU2F1_new',
    'SU2M5',
    'SU3F6',
    'SU3M10_new',
  ],
  [
    'A1F1_new',
    'A1M7',
    'A2F10',
    'A2M7_new',
    'A3F6_new',
    'A3M11',
    'D1F7',
    'D1M9_new',
    'D2F4_new',
    'D2M8',
    'D3F2_new',
    'D3M10',
    'F1F10',
    'F1M6_new',
    'F2F3',
    'F2M5_new',
    'F3F7_new',
    'F3M3',
    'H1F8_new',
    'H1M5',
    'H2F5_new',
    'H2M1',
    'H3F8',
    'H3M6_new',
    'N1F2_new',
    'N1M5',
    'N2F3',
    'N2M4_new',
    'N3F8',
    'N3M3_new',
    'S1F9',
    'S1M9_new',
    'S2F4_new',
    'S2M3',
    'S3F5_new',
    'S3M9',
    'SU1F6_new',
    'SU1M10',
    'SU2F1_new',
    'SU2M5',
    'SU3F6',
    'SU3M10_new',
  ],
  [
    'A1F6',
    'A1M1_new',
    'A2F8_new',
    'A2M6',
    'A3F3',
    'A3M7_new',
    'D1F1_new',
    'D1M4',
    'D2F7',
    'D2M3_new',
    'D3F3',
    'D3M9_new',
    'F1F6_new',
    'F1M1',
    'F2F9_new',
    'F2M7',
    'F3F2',
    'F3M8_new',
    'H1F2',
    'H1M9_new',
    'H2F1',
    'H2M7_new',
    'H3F2_new',
    'H3M9',
    'N1F10',
    'N1M8_new',
    'N2F1_new',
    'N2M10',
    'N3F2_new',
    'N3M8',
    'S1F3_new',
    'S1M6',
    'S2F5',
    'S2M1_new',
    'S3F6',
    'S3M12_new',
    'SU1F10',
    'SU1M7_new',
    'SU2F8',
    'SU2M3_new',
    'SU3F1_new',
    'SU3M5',
  ],
];

export default {
  data() {
    return {
      isPractice: true, //是否练习题
      showStatus: 1, //1、光标+集中 2、答题 3、黑屏 4、结果
      current: 0, //当前进度
      btnList: [
        { key: 'Q', name: '出现过' },
        { key: 'P', name: '没出现过' },
      ],
      memoryList: [], //数据
      original: [], //用户原始数据
      startTimeTotal: 0, //起始时间
      endTimeTotal: 0, //结束时间
      totalTime: 0, //用时
      timer: null, //计时器
      timerSubmit1: null,
      timerSubmit2: null,
      praTimer: null,
      active: '', //高亮显示
      correct_type: '',
      showFace: true,
    };
  },
  components: {
    Practice,
    Focus,
    BlackScreen,
    isRight,
  },
  watch: {
    current() {
      this.progress();
    },
  },
  methods: {
    //初始化数据
    initData() {
      this.$util.shuffle(this.memoryList);
      this.memoryList.forEach((item) => {
        this.original.push({
          id: item.id,
          correct_answer: item.correct_answer,
        });
      });
      this.progress();
    },
    //用户事件
    choose(keyName) {
      this.endTime = this.$util.getTimestamp();
      if (this.original[this.current].answer) {
        //已作答
        return false;
      } else {
        this.showFace = false;
        this.original[this.current].answer = keyName;
        this.original[this.current].time = this.endTime - this.startTime;
        if (keyName == this.original[this.current].correct_answer) {
          this.original[this.current].correct_type = 1;
          if (this.isPractice) {
            this.correct_type = this.original[this.current].correct_type;
            this.showStatus = 4;
            setTimeout(() => {
              this.progressNext();
            }, 1000);
          } else {
            this.progressNext();
          }
        } else {
          this.original[this.current].correct_type = 0;
          if (this.isPractice) {
            this.correct_type = this.original[this.current].correct_type;
            this.showStatus = 4;
            setTimeout(() => {
              this.progressNext();
            }, 1000);
          } else {
            this.progressNext();
          }
        }
        clearTimeout(this.timer);
      }
    },
    //进行中
    progress() {
      this.focusTimer = setTimeout(() => {
        this.showStatus = 2;
        this.showFace = true;
        this.active = '';
        this.startTime = this.$util.getTimestamp();
      }, 800);
      this.timer = setTimeout(() => {
        this.showFace = false;
      }, 1800);
      if (this.isPractice) {
        this.praTimer = setTimeout(() => {
          if (!this.original[this.current].answer) {
            //用户未操作
            this.original[this.current].answer = null;
            this.original[this.current].time = null;
            this.original[this.current].correct_type = 0;
            this.correct_type = this.original[this.current].correct_type;
            this.showStatus = 4;
            setTimeout(() => {
              this.progressNext();
            }, 1000);
          }
        }, 6000);
      }
      // this.timer = setTimeout(() => {
      //   if (!this.original[this.current].answer) {
      //     //用户未操作
      //     this.original[this.current].answer = null;
      //     this.original[this.current].time = null;
      //     this.original[this.current].correct_type = 0;
      //     if (this.isPractice) {
      //       this.correct_type = this.original[this.current].correct_type;
      //       this.showStatus = 4;
      //       setTimeout(() => {
      //         this.progressNext();
      //       }, 1000);
      //     } else {
      //       this.progressNext();
      //     }
      //   }
      // }, 6500);
    },
    //进行中
    progressNext() {
      // setTimeout(() => {
      this.showStatus = 3;
      // }, 100);
      if (this.current >= this.memoryList.length - 1) {
        //完成
        this.finish();
      } else {
        clearTimeout(this.praTimer);
        // setTimeout(() => {
        this.current++;
        this.showStatus = 1;
        this.active = '';
        // }, 1500);
      }
    },
    finish() {
      this.showStatus = 3;
      this.endTimeTotal = this.$util.getTimestamp();
      this.totalTime = this.endTimeTotal - this.startTimeTotal;
      if (this.isPractice) {
        //练习题
        // if (this.totalTime > 21000 - 1000) {
          this.$router.replace({
            path: '/practiceEnd',
            query: {
              source: 'faceMemoryTip',
            },
          });
        // } else {
        //   this.$router.replace({
        //     path: '/relax',
        //     query: {
        //       time: 21000 - this.totalTime,
        //       path: '/practiceEnd?source=faceMemoryTip',
        //     },
        //   });
        // }
      } else {
        let query = {
          fmUserId: this.$util.getStorage('fmUserId'),
          fmProductQuestionId: '2',
          quesNum: '2-3',
          totalTime: this.totalTime > 240000 ? 240000 : this.totalTime,
          dataJason: JSON.stringify(this.original),
          remark:
            'id：图片编号；answer：用户答案；correct_answer：正确答案；time:用时；correct_type：是否正确，1正确 0错误；',
        };
        saveSubjectiveAns(query)
          .then((res) => {
            if (res) {
              //大题编号
              sessionStorage.setItem(
                'fmProductQuestionId',
                res.fmProductQuestionId
              );
              //小题编号
              sessionStorage.setItem('quesNum', res.quesNum);
              //是否完成
              sessionStorage.setItem('isFinished', res.isFinished);
              // if (this.totalTime > 240000 - 1000) {
                this.$router.replace({
                  path: '/getCard',
                  query: {
                    id: 2,
                    current: '2-3',
                  },
                });
              // } else {
              //   this.$router.replace({
              //     path: '/relax',
              //     query: {
              //       time: 240000 - this.totalTime,
              //       path: '/getCard?id=2&current=2-3',
              //     },
              //   });
              // }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
  beforeDestroy() {
    document.onkeyup = '';
    clearTimeout(this.focusTimer);
    clearTimeout(this.timer);
    clearTimeout(this.timerSubmit1);
    clearTimeout(this.timerSubmit2);
    clearTimeout(this.praTimer);
  },
  created() {
    if (this.$route.query.practice) {
      this.isPractice = false;
    }
    if (this.isPractice) {
      this.memoryList = practiceData;
      this.initData();
      this.timerSubmit1 = setTimeout(() => {
        this.finish();
      }, 21000);
    } else {
      let randomId = this.$util.getRandomArrEle(idData, 1)[0];
      let formalData = [];
      randomId.forEach((item) => {
        if (item.includes('new')) {
          formalData.push({
            id: item,
            correct_answer: 'P',
            src: require('../../assets/image/face/memory/' + item + '.jpg'),
          });
        } else {
          formalData.push({
            id: item,
            correct_answer: 'Q',
            src: require('../../assets/image/face/recognition/' +
              item +
              '.jpg'),
          });
        }
      });
      //从已出现过的随机取14个
      // let QdataRandom = this.$util.getRandomArrEle(Qdata, 14);
      // let formalData = QdataRandom.concat(Pdata);
      this.memoryList = formalData;
      this.initData();
      this.timerSubmit2 = setTimeout(() => {
        this.finish();
      }, 240000);
    }
  },
  mounted() {
    this.startTimeTotal = this.$util.getTimestamp();
    document.onkeyup = (e) => {
      if (this.showStatus == 2) {
        if (e.keyCode == 80) {
          this.choose('P');
          this.active = 'P';
        }
        if (e.keyCode == 81) {
          this.choose('Q');
          this.active = 'Q';
        }
      }
    };
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .memory {
    display: table-cell;
    vertical-align: middle;
    .container {
      text-align: center;
      .faceBox {
        width: 260px;
        height: 300px;
        display: inline-block;
        border-radius: 8px;
        margin-bottom: 80px;
        color: #ffffff;
        img {
          width: 100%;
          border-radius: 8px;
        }
      }
      .btns {
        width: 330px;
        margin: 0 auto;
        overflow: hidden;
        li {
          width: 100px;
          text-align: center;
          display: inline-block;
          .keyBtn {
            width: 70px;
            height: 70px;
            line-height: 70px;
            margin: 0 auto;
            text-align: center;
            background-image: url('../../assets/image/other/letter.png');
            background-repeat: no-repeat;
            background-size: cover;
            font-size: 34px;
            color: #ffffff;
            font-family: 'SYHeiBold';
          }
          .name {
            margin-top: 20px;
            color: #5f9160;
            font-size: 24px;
            font-family: 'SYHeiBold';
          }
          .active {
            background-image: url('../../assets/image/other/letter_check.png');
          }
          .activeName {
            color: #fa712d;
          }
        }
        li:first-child {
          float: left;
        }
        li:last-child {
          float: right;
        }
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .memory {
    display: table-cell;
    vertical-align: middle;
    .container {
      text-align: center;
      .faceBox {
        width: 338px;
        height: 390px;
        display: inline-block;
        border-radius: 10px;
        margin-bottom: 104px;
        color: #ffffff;
        img {
          width: 100%;
          border-radius: 10px;
        }
      }
      .btns {
        width: 429px;
        margin: 0 auto;
        overflow: hidden;
        li {
          width: 130px;
          text-align: center;
          display: inline-block;
          .keyBtn {
            width: 91px;
            height: 91px;
            line-height: 91px;
            margin: 0 auto;
            text-align: center;
            background-image: url('../../assets/image/other/letter.png');
            background-repeat: no-repeat;
            background-size: cover;
            font-size: 44px;
            color: #ffffff;
            font-family: 'SYHeiBold';
          }
          .name {
            margin-top: 26px;
            color: #5f9160;
            font-size: 31px;
            font-family: 'SYHeiBold';
          }
          .active {
            background-image: url('../../assets/image/other/letter_check.png');
          }
          .activeName {
            color: #fa712d;
          }
        }
        li:first-child {
          float: left;
        }
        li:last-child {
          float: right;
        }
      }
    }
  }
}
</style>
